/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/notices/1372244"
  }, "인프런 앱 영상 다운로드 기능 출시 소식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://x.com/jojoldu/status/1815733732839690292/history"
  }, "리누스 토르발스의 C언어를 좋아하는 이유(우리말 더빙 예시)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.inflearn.com/pages/why-global"
  }, "왜 인프런은 글로벌에 도전할까?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.raccoony.dev/language-is-not-the-only-reason-startups-are-hard-to-hire/"
  }, "스타트업 채용, 정말 언어 문제일까요?(by 너굴)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jojoldu.tistory.com/609"
  }, "자바 공화국(by 향로)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/@devbadak"
  }, "개발바닥 유튜브 채널")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
